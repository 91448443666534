
var folders = {
  methods: {
      urlRespuestas(item){

        console.log(item)
        let url = 'https://drive1.educandote.co/siett/'+item.token+'/'+item.nombre+'.'+item.tipo
        console.log(url)
        return url
      },
      prueba(){
        console.log(this.datosInicio)
      }
  },
  
}

export default folders 