<template>
  <v-app>
    <v-parallax
      src="https://picsum.photos/1920/1080?random"
      :height="altura"
      :width="ancho"
    >
      <v-main>
        <v-container fluid class="fill-height">
          <v-row  align="center" justify="center">
            <v-card v-on:keydown.enter="login()" class="elevation-12 my-auto mx-5" shaped max-width="700">
              <v-progress-linear
                indeterminate
                class="mx-auto text-center"
                color="blue darken-4"
                v-show="load"
              />
              <v-window>
                <v-window-item>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="8"
                      class="blue-grey lighten-3"
                    >
                      <v-card-text class="mt-12">
                        <h1 class="text-center display-3 mb-5">
                          <span class="indigo--text text--darken-4">
                            <strong>Audi</strong>
                          </span>
                          <span class="blue--text text--darken-3">
                            <strong>Doc</strong>
                          </span>
                        </h1>
                        <div class="text-center" mt-4></div>

                        <!---->
                        <v-form>
                          <v-container fluid>
                            <v-text-field
                              label="Cuenta"
                              name="Email"
                              prepend-icon="mdi-account blue--text text--darken-3"
                              type="text"
                              v-model="datosInicio.usuario"
                              rounded
                              background-color="rgba(238,249,253,.1)"
                            />
                            <v-text-field
                              rounded
                              label="Password"
                              name="Password"
                              v-model="datosInicio.clave"
                              prepend-icon="mdi-lock blue--text text--darken-3"
                              type="password"
                              background-color="rgba(238,249,253,.1)"
                            />
                          </v-container>

                          <div class="text-center mt-5">
                            <v-btn
                              rounded
                              color="indigo darken-4"
                              text
                              block
                              @click="login()"
                              >Ingresar</v-btn
                            >
                          </div>
                        </v-form>
                      </v-card-text>
                      <v-divider vertical></v-divider>
                    </v-col>

                    <v-col cols="10" sm="4" class="mx-auto" >
                      <v-container class=" fill-height" fluid>
                        <v-img
                         
                          contain
                          :aspect-ratio=" ancho<600 ?  16/9  : null"
                          :src="require('../assets/img.png')"
                        />
                      </v-container>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-row>
        </v-container>
      </v-main>
    </v-parallax>
  </v-app>
</template>
<script>
const axios = require("axios").default;
import folders from "../mixins";
export default {
  mixins: [folders],
  data() {
    return {
      datosInicio: {
        usuario: "",
        clave: "",
      },
      altura: null,
      ancho: null,
      load: false
    };
  },
  async created() {
    //this.$vuetify.theme.dark = true;
    //this.$router.push('/admin')
    this.altura = window.innerHeight;
    this.ancho = window.innerWidth;
     

      
    //this.crear();
  },

  methods: {
    
    async login() {
      this.load = true
      this.$store.commit("auth", "parse");
      console.log(this.$store.state.header);

     

      try {
        let buscar_login = JSON.stringify({
          usuario: this.datosInicio.usuario,
          clave: this.datosInicio.clave,
        });

        buscar_login = encodeURIComponent(buscar_login);

        let response = await axios.get(
          "https://siett.educandote.co/parse/classes/prueba01Login?where=" +
            buscar_login,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
     
        //Validamos primero que la cuenta exista en el login
        if (response.data.results.length == 0) {
          console.log("Cuenta no encontrada");
          this.$store.commit('error', 'Error en usuario o contraseña')
        } else {
             console.log(response)
          //Si existe la cuenta en el login extraemos los datos de la cuenta en este endpoint
          //La forma de buscar sera utilizando la identificacion del usuario la cual sera un dato unico
          let buscar_usuario = JSON.stringify({
            userId: response.data.results[0].objectId,
          });

          buscar_login = encodeURIComponent(buscar_login);
          let response2 = await axios.get(
            "https://siett.educandote.co/parse/classes/prueba03Usuarios?where=" +
              buscar_usuario,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(response2.data);
          //this.$store.state.usuario = response2.data.results[0].usuario;
          this.$store.state.idUsuario = response2.data.results[0].identificacion;
          this.$store.commit("dataUser/DO_LOGIN", response2.data.results[0]);
          

          console.log(this.$store.state.dataUser.dataUser);
          
           let cargo = response2.data.results[0].rango;
           if(cargo=='admin'){
             this.$router.push("/admin");
           }else{
              this.$router.push({name: "home_rol" });
              //this.$router.push({name: "pruebas" })
           }
        }
      } catch (error) {
        console.log(error);
      }
      this.load = false
    },
  },
};
</script>