import axios from "axios";

export default () => {
  return axios.create({
    baseURL: "https://siett.educandote.co/types/collections",
    headers: {
      "Content-Type": "application/json",
      "X-TYPESENSE-API-KEY": "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
    },
  });
};
