<template>
  <v-app>
    <!-- <pre>{{ result }}</pre> -->
    <AppBar></AppBar>
    <v-container fluid v-if="$route.name != 'Folders'">
      <v-expand-transition>
        <DataTable
          :desserts="desserts"
          :headers="headers"
          @show-document-folder="showFolders"
        ></DataTable>
      </v-expand-transition>
    </v-container>

    <v-container
      :class="`d-flex flex-row flex-wrap justify-center align-start pa-4 ma-0`"
      fluid
      fill-height
      v-else
    >
      <router-view class="view"></router-view>
    </v-container>
  </v-app>
</template>

<script>
//http://localhost:8080/qr?placas=LLM123,LLM124,HQK456,HQK457,HQK376,LLM334
import DataTable from "../../components/qr/DataTable.vue";
import AppBar from "../../components/qr/AppBar.vue";
import SiettServices from "../../services/SiettServices";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      placas: [],
      desserts: [],
      headers: [
        { text: "Acciones", value: "actions", sortable: false },
        { value: "Placa", text: "Placa" },
        { value: "Rango_placa", text: "Rango" },
        { value: "Numero_rango_placa", text: "Numero rango placa" },
        { value: "Parque_automotor", text: "Parque automotor" },
        { value: "Tipo_de_tramite", text: "Tipo de tramite" },
        { value: "Fecha", text: "Fecha" },
        { value: "Tipo_de_vehiculo", text: "Vehiculo" },
        {
          value: "Documento_del_propietario",
          text: "Documento del propietario",
        },
        { value: "Observaciones", text: "Observaciones" },
        { value: "estatus", text: "Estatus" },
        { value: "autor", text: "Autor" },
      ],
      result: null,
      //searchDocuments
      documentsPerPage: 250,
    };
  },
  components: {
    DataTable,
    AppBar,
  },
  methods: {
    async showFolders({ item }) {
      var array = [];
      try {
        const { data: carpetas } = await SiettServices.obtenerCarpetas(item.id);

        let page = 1;
        for (let index = 1; index <= page; index++) {
          const {
            data: imagenes,
          } = await SiettServices.obtenerImagenesDeCarpeta(
            carpetas.hits[0].document.id
          );

          const buscarPorPagina = Math.ceil(
            imagenes.found / this.documentsPerPage
          );

          if (index == 1)
            page = Math.ceil(buscarPorPagina / this.documentsPerPage);

          array.push(...imagenes.hits);
        }

        this.$router.push({
          name: "Folders",
          params: { id: item.id, data: array },
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      }
    },
    initDataTable(data) {
      data.forEach((ele) => {
        this.desserts.push(ele.document);
      });

      this.headers.forEach((element) => {
        element.class = [
          "text-capitalize",
          "font-weight-ligth",
          "grey--text",
          "text-subtitle-2",
          "text-truncate",
        ];
        element.align = "start";
        element.sortable = true;
        element.width = "125px";
      });
    },
    async ObtenerPlacas() {
      this.desserts = [];
      let array = [];
      try {
        let page = 1;
        for (let index = 1; index <= page; index++) {
          const { data } = await SiettServices.obtenerPlacasQR(
            `${this.placas}`
          );

          const buscarPorPagina = Math.ceil(data.found / this.documentsPerPage);

          if (index == 1)
            page = Math.ceil(buscarPorPagina / this.documentsPerPage);

          array.push(...data.hits);
        }
        this.result = array;
        return array;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...  ",
          text: error.message,
        });
        throw new Error(error.message);
      }
    },
    async ObtenerPlacasUr() {
      try {
        if (this.$route.query.placas) {
          this.placas = this.$route.query.placas.split(",");
          const data = await this.ObtenerPlacas();
          this.initDataTable(data);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No hay datos de busqueda",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      }
    },
  },
  async created() {
    console.log(this.$route);
    await this.ObtenerPlacasUr();
  },
};
</script>
