<template>
  <v-app>
    <!-- <pre>{{ result }}</pre> -->
    <AppBar></AppBar>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn text color="primary" @click="comenzarProceso">
            Obtener Informe
          </v-btn>
          <vue-excel-xlsx
            :data="desserts"
            :columns="excelColumn"
            :file-name="'Reporte'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
          >
            <v-btn text color="green darken-4" class="mt-1">
              <span>Descargar</span>
              <v-icon>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </vue-excel-xlsx>
        </v-col>
      </v-row>
      <v-expand-transition>
        <DataTable
          :desserts="desserts"
          :headers="headers"
          :loading="loadFiles"
          @show-document-folder="showFolders"
        ></DataTable>
      </v-expand-transition>
    </v-container>
  </v-app>
</template>

<script>
import VueExcelXlsx from "vue-excel-xlsx";
import DataTable from "../../components/qr/DataTableLoading.vue";
import AppBar from "../../components/qr/AppBar.vue";
import SiettServices from "../../services/SiettServices";
import Swal from "sweetalert2";
import Vue from "vue";
Vue.use(VueExcelXlsx);
export default {
  data() {
    return {
      loadFiles: false,
      placas: [],
      desserts: [],
      excelColumn: [],
      headers: [],
      result: null,
      documentsPerPage: 250,
      numeroProcesoCaja: 0,
      numeroProcesoImagen: 0,
    };
  },
  components: {
    DataTable,
    AppBar,
  },
  methods: {
    async showFolders({ item }) {
      console.log(item);
      var array = [];
      try {
        const { data: carpetas } = await SiettServices.obtenerCarpetas(item.id);
        console.log(carpetas);
        let page = 1;
        for (let index = 1; index <= page; index++) {
          const {
            data: imagenes,
          } = await SiettServices.obtenerImagenesDeCarpeta(
            carpetas.hits[0].document.id
          );

          const buscarPorPagina = Math.ceil(
            imagenes.found / this.documentsPerPage
          );

          if (index == 1)
            page = Math.ceil(buscarPorPagina / this.documentsPerPage);

          array.push(...imagenes.hits);
        }

        console.log(array);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      }
    },
    initDataTable(data) {
      data.forEach((ele) => {
        this.desserts.push(ele.document);
      });

      for (const [key] of Object.entries(data[0].document)) {
        this.headers.push({
          value: key,
          text: key.replace(/_/g, " ").toLocaleUpperCase(),
        });

        this.excelColumn.push({
          label: key.replace(/_/g, " ").toLocaleUpperCase(),
          field: key,
        });
      }

      this.headers.forEach((element) => {
        element.class = [
          "text-capitalize",
          "font-weight-ligth",
          "grey--text",
          "text-subtitle-2",
          "text-truncate",
        ];
        element.align = "start";
        element.sortable = true;
        element.width = "125px";
      });
    },
    async ObtenerDocumentosSiett() {
      let array = [];

      try {
        const { data } = await SiettServices.obtenerTodo(1);
        const page = Math.ceil(data.found / this.documentsPerPage);

        for (let index = 1; index <= page; index++) {
          this.numeroProcesoCaja = index;
          const { data } = await SiettServices.obtenerTodo(index);

          array.push(...data.hits);
        }

        for (let index = 0; index < array.length; index++) {
          const { data } = await SiettServices.obtenerImagenesPorCaja(
            array[index].document.id
          );
          this.numeroProcesoImagen = index;
          if (data.found > 0) {
            array[index].document.token = data.hits[0].document.token;
          } else {
            array[index].document.token = null;
          }
        }

        return array;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...  ",
          text: error.message,
        });
        throw new Error(error.message);
      }
    },
    async comenzarProceso() {
      this.desserts = [];
      this.headers = [];
      this.excelColumn = [];
      this.loadFiles = true;
      var data = await this.ObtenerDocumentosSiett();
      this.initDataTable(data);

      this.loadFiles = false;
    },
  },
  created() {},
};
</script>
