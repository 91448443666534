<template>
  <v-app-bar
    class="navbar"
    app
    flat
    color="transparent"
    height="75"
    hide-on-scroll
  >
    <!-- v-if="$vuetify.breakpoint.mobile" -->

    <v-toolbar-title>
      <!-- {{ pageTitle }} -->
      AudiDoc
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn flat outline text color="primary" @click="$router.push('/')">
      Iniciar sesion
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      group: null,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appbar__logo {
  display: block;
  width: 150px;
  height: 100%;
  // background: url("@/assets/svg/logo_navbar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
