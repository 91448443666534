import Axios from "./Api.js";

export default {
  async obtenerTodo(page) {
    try {
      return await Axios().get(
        `/V1_SIETT_DOCUMENT_V3_3/documents/search?q=*&query_by=Placa&filter_by=estatus:[HABILITADA,INHABILITADA]&per_page=250&page=${page}`
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async obtenerPlacasQR(placas) {
    try {
      return await Axios().get(
        `/V1_SIETT_DOCUMENT_V3_3/documents/search?q=*&query_by=Placa&filter_by=Placa:[${placas}]&&estatus:[HABILITADA,INHABILITADA]&per_page=250&page=1`
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async obtenerCarpetas(id) {
    try {
      return await Axios().get(
        `https://siett.educandote.co/types/collections/CARPETAS_V1_SIETT_DOCUMENT_V3_2/documents/search?q=${id}&query_by=${"pertenece_a"}&filter_by=pertenece_a:[${id}]&per_page=${250}`
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async obtenerImagenesDeCarpeta(id) {
    try {
      return await Axios().get(
        `https://siett.educandote.co/types/collections/IMAGENES_V1_SIETT_DOCUMENT_V3_2/documents/search?q=${id}&query_by=${"id_carpeta"}&filter_by=estado_imagen:[habilitada]&&id_carpeta:[${id}]&per_page=${250}&sort_by=order:asc`
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async obtenerImagenesPorCaja(id) {
    try {
      return await Axios().get(
        `https://siett.educandote.co/types/collections/IMAGENES_V1_SIETT_DOCUMENT_V3_2/documents/search?q=${id}&query_by=${"id_busqueda"}&filter_by=estado_imagen:[habilitadas,INHABILITADA]&per_page=${250}&sort_by=order:asc`
      );
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
