var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transition-fast-in-fast-out v-card--revealDataTabla levation-0",attrs:{"dense":"","items":_vm.desserts,"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"header-props":_vm.headerProps,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    'items-per-page-text': 'Documentos por pagina',
  },"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(ref){
  var pagination = ref.pagination;
  var options = ref.options;
  var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","pagination":pagination,"options":options,"page-text":((pagination.pageStart + 1) + " - " + (pagination.pageStop) + " de\n          " + (pagination.itemsLength)),"items-per-page-text":"Documentos por pagina"},on:{"update:options":updateOptions}}),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-start"},[_c('v-text-field',{staticClass:"shrink",attrs:{"width":"200","dense":"","single-line":"","filled":"","rounded":"","append-icon":"mdi-magnify","suffix":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return _vm._l((_vm.actions),function(action,id){return _c('v-tooltip',{key:id,attrs:{"dark":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":item,"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.handlerActions(action.method, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(action.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.text))])])})}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"dark":"","value":true,"color":"error"}},[_vm._v(" No se encontraron documentos. ")])]},proxy:true},{key:"item.estatus",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.estatus == 'HABILITADA' ? 'green' : 'orange'}},[_vm._v(" "+_vm._s(item.estatus)+" "),(
          _vm.$store.state.dataUser.permisos_buscador['Buscador completo'] &&
            item.estatus == 'INHABILITADA'
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"color":"indigo","dark":"","icon":""},on:{"click":function($event){return _vm.habilitar_busqueda(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-reload-alert")])],1)]}}],null,true)},[_c('span',[_vm._v("Recuperar busqueda")])]):_vm._e()],1)]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"dark":"","value":true,"color":"error"}},[_vm._v(" No hay documentos ralacionados con la busqueda. ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }