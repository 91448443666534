<template>
  <v-data-table
    class="transition-fast-in-fast-out v-card--revealDataTabla levation-0"
    dense
    :items="desserts"
    :items-per-page="itemsPerPage"
    :headers="headers"
    :header-props="headerProps"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      'items-per-page-text': 'Documentos por pagina',
    }"
    :search="search"
  >
    <!--   @click:row="handleClick" -->
    <!-- Footer Data Table on Top -->
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-data-footer
        :showFirstLastPage="true"
        firstIcon="mdi-arrow-collapse-left"
        lastIcon="mdi-arrow-collapse-right"
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        :page-text="
          `${pagination.pageStart + 1} - ${pagination.pageStop} de
            ${pagination.itemsLength}`
        "
        items-per-page-text="Documentos por pagina"
      />

      <div class="d-flex flex-wrap justify-space-between align-start">
        <v-text-field
          class="shrink"
          width="200"
          dense
          single-line
          filled
          rounded
          append-icon="mdi-magnify"
          suffix="Buscar"
          v-model="search"
        ></v-text-field>
      </div>
    </template>

    <!-- Custom actions -->
    <template v-slot:item.actions="{ item }">
      <v-tooltip dark bottom v-for="(action, id) in actions" :key="id">
        <template v-slot:activator="{ on, attrs }">
          <!-- @click="handlerActions(action.method, item)" -->
          <v-btn
            @click="handlerActions(action.method, item)"
            :id="item"
            color="primary"
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="25"> {{ action.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ action.text }}</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>

    <template v-slot:item.estatus="{ item }">
      <v-chip dark :color="item.estatus == 'HABILITADA' ? 'green' : 'orange'">
        {{ item.estatus }}
        <v-tooltip
          v-if="
            $store.state.dataUser.permisos_buscador['Buscador completo'] &&
              item.estatus == 'INHABILITADA'
          "
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              dark
              class="mt-0"
              icon
              @click="habilitar_busqueda(item)"
            >
              <v-icon> mdi-reload-alert</v-icon>
            </v-btn>
          </template>
          <span>Recuperar busqueda</span>
        </v-tooltip>
      </v-chip>
    </template>
    <!-- Custom Footer.page.text-->
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} de
      {{ items.itemsLength }}
    </template>

    <!-- Custom not data found -->
    <template v-slot:no-data>
      <v-alert dark :value="true" color="error">
        No se encontraron documentos.
      </v-alert>
    </template>
    <template v-slot:no-results>
      <v-alert dark :value="true" color="error">
        No hay documentos ralacionados con la busqueda.
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    title: String,
    desserts: Array,
    headers: Array,
  },
  components: {},

  data() {
    return {
      itemsPerPage: 10,
      search: "",
      page: 1,
      headerProps: {
        sortByText: "Filtrar por",
      },
      actions: [
        {
          icon: "mdi-folder-information-outline",
          text: "Datos Archivados",
          method: "showDocumentFolders",
        },
      ],
    };
  },
  methods: {
    fummyFuction(item) {
      console.log(item);
    },
    editRow(item) {
      console.log({ editRow: item });
    },
    showDocumentFolders(item) {
      this.$emit("show-document-folder", { activador: true, item });
    },
    handleClick(event) {
      console.log({ handleClick: event });
    },
    handlerActions(method, item) {
      this[method](item);
      console.log(method, item);
    },
    formatedDate(date) {
      return date;
      // return moment(date).format() !== "Invalid date"
      //   ? moment(date).format("YYYY/MM/DD")
      //   : date;
    },
  },
};
</script>

<style lang="scss" scoped></style>
